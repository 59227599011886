body {
    position: relative;
    font-family: museo-slab, serif;
    font-style: normal;
}

.landingpage {
    //special background for legal pages
    &.legal {
        main {
            &.app {

                background: rgb(88, 74, 242);
                background: linear-gradient(180deg, rgba(88, 74, 242, 1) 0%, rgba(192, 21, 241, 1) 100%);
            }
        }
    }

    h1, h2, h3, h4 {
        font-family: itc-avant-garde-gothic-pro, sans-serif;
    }
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    h3 {
        font-size: 1rem;
    }
    h4 {
        font-size: 0.5rem;
    }
    .btn-rounded {
        border-radius: 20px !important;
    }
    .btn-md {
        color: $white;
    }
    .btn-outline-primary {
        border-color: $white;

        &:hover {
            background-color: $white;
            color: $purple;
        }
    }
    .text-dark-blue {
        color: $dark-blue !important;
    }
    .text-purple {
        color: $purple !important;
    }

    .fs-1 {
      &.statement {
          @include media-breakpoint-up(lg) {
              font-size: 3.5rem !important;
          }
      }
    }

    .custom-form-control {
        background-image: none !important;
        background-clip: padding-box;
        border: 1px solid #ced4da !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.25rem !important;
    }

    .custom-form-check-input {
        background-color: initial !important;
        border-color: initial !important;
        &:checked {
            background-color: #0d6efd !important;
            border-color: #0d6efd !important;
        }
    }

    .custom-form-check-label {
        color: unset !important;

    }

    @import "snippets/polygons";

    header {
        box-shadow: #0000001c 10px 10px 10px;
        &.site-header {
            background-color: $white;
            -webkit-backdrop-filter: saturate(180%) blur(20px);
            backdrop-filter: saturate(180%) blur(20px);

            .navbar-light {
                @include media-breakpoint-down(lg) {
                    align-items: center !important;
                    justify-content: space-between;
                }
            }

            #nav-options {
                @include media-breakpoint-down(lg) {
                    display: none !important;
                }
            }

            .navbar-toggler {
                border: none;
            }

            img {
                max-width: 120px !important;
                height: 50px;
            }

            a {
                font-weight: 600;
                color: $dark-brown;
                text-decoration: none;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    background: $purple;
                    height: 2px;
                    width: 120%;
                    bottom: -5%;
                    left: -10%;
                    transition: all 0.3s;
                    -webkit-transition: all 0.3s;
                    opacity: 0;
                }
                &:hover {
                    color: $dark-brown;
                    text-decoration: none;
                }
            }
            #nav-options {
                a {
                    &:hover {
                        @include media-breakpoint-up(md) {
                            &:after {
                                opacity: 1;
                                bottom: -2%;
                            }
                        }
                    }
                }
            }
        }
    }

    .calendly {
        bottom: 20px;
        right: 20px;
        z-index: 999;

        img {
            max-width: 160px !important;
        }
    }

    #calendlyModal {
        .modal-body {
            min-height: 400px;
        }
    }

    .btn-primary {
        background-color: $purple;
        border-color: $purple;
        &:hover {
            background-color: $dark-purple;
            border-color: $dark-purple;
        }
        &:focus {
            background-color: $dark-purple;
            border-color: $dark-purple;
            box-shadow: 0 0 0 0.25rem rgb(111 66 193 / 50%);
        }
    }


    main {
        /* make sure to cover the screen */
        min-height: 100vh;

        /* put on top */
        position: relative;
        z-index: 1;
        max-width: 100%;
        &.app {
            overflow-x: hidden;
            background: $white url("/assets/backgrounds/fluid-1920.png") 0% 0% no-repeat padding-box;
            background-size: cover;
            @include media-breakpoint-down(md) {
                background: rgb(88, 74, 242);
                background: linear-gradient(180deg, rgba(88, 74, 242, 1) 0%, rgba(192, 21, 241, 1) 100%);
            }
            @include media-breakpoint-down(sm) {
                background: rgb(88,74,242);
                background: linear-gradient(180deg, rgba(88,74,242,1) 0%, rgba(192,21,241,1));
            }
            @include media-breakpoint-up(xl) {
                background: $white url("/assets/backgrounds/fluid-1920.png") 0% 0% no-repeat padding-box;
                background-position-x: center;
            }
        }
    }

    .intro {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;

        .btn-outline-primary {
            &:hover {
                color: $dark-blue;
            }
        }
        .mobile-fix {
            z-index: 2;
            @include media-breakpoint-up(xl) {
                h1, h2 {
                    width: 120%;
                }
                h1 {
                    font-size: 2.5rem;
                }
                h2 {
                    font-size: 1.75rem;
                }
            }
        }
        @include media-breakpoint-up(md) {
            min-height: 700px;
        }
        @include media-breakpoint-down(md) {
            min-height: 1000px;
            .mobile-fix {
                padding-top: 3rem !important;
                padding-bottom: 3rem !important;
                position: absolute;
                top: 0;
            }
        }

        .offc-wrapper {
            overflow-x: hidden;
            overflow-y: hidden;
            -ms-overflow-x: hidden;
            //dirty workaround for scroll behaviour
            padding-bottom: 250px;
            margin-bottom: -250px;
        }
        .offc {
            max-width: 320px;
            margin-top: 14rem !important;
            .light {
                max-width: 47%;
                position: absolute;
                transform: rotate(12deg);
                @media (max-width: 375px) {
                    display: none;
                }
                &-1 {
                    top: 114px;
                    left: -98px;
                }
                &-2 {
                    top: 48px;
                    right: -88px;
                }
                &-3 {
                    left: -98px;
                    top: 248px;
                }
                &-4 {
                    top: 182px;
                    right: -88px;
                }
                &-5 {
                    left: -98px;
                    top: 382px;
                }
                &-6 {
                    top: 316px;
                    right: -88px;
                }
                &-7 {
                    top: 518px;
                    right: 30px;
                }
                &-8 {
                    top: -22px;
                    right: 30px;
                }
                &-9 {
                    top: 450px;
                    right: -88px;
                }
            }
            .col {
                //z-index: 1;
            }
            p {
                font-family: 'Roboto';
            }
            .first {
                img {
                    z-index: 22;
                    width: 110%;
                    &:not(.light) {
                        transform: translate(10px, 16px) rotate(12deg);

                        &.stopper {
                            transform: translate(10px, 0px) rotate(12deg);
                        }
                    }
                }
                p {
                    z-index: 22;
                    text-align: center;
                    top: 46%;
                    left: 42%;
                    position: absolute;
                    &.stopper {
                        top: 36%;
                        left: 38%;
                    }
                }
            }
            .second {
                img {
                    z-index: 22;
                    width: 110%;
                    transform: translate(-32px, 82px) rotate(12deg);
                }
                p {
                    z-index: 22;
                    text-align: center;
                    top: 92%;
                    left: 10%;
                    position: absolute;
                }
            }
            .wrapper- {
                &1{
                    .first {
                        img {
                            transform: translate(10px,48px) rotate(12deg);
                        }
                        p {
                            top: 70%;
                            left: 40%;
                            font-family: 'Roboto';
                        }
                    }
                    .second {
                        img {
                            transform: translate(-32px,112px) rotate(12deg);
                        }
                        p {
                            top: 110%;
                            left: 12%;
                            font-family: 'Roboto';
                        }
                    }
                }
                &2{
                    .first {
                        img {
                            transform: translate(10px,32px) rotate(12deg);
                        }
                        p {
                            top: 60%;
                            left: 40%;
                        }
                    }
                    .second {
                        img {
                            transform: translate(-32px,98px) rotate(12deg);
                        }
                        p {
                            top: 102%;
                            left: 12%;
                        }
                    }
                }
            }
        }

        .polygons {
            @include media-breakpoint-down(sm) {
                transform: translateX(-40px);
            }
            @include media-breakpoint-up(xl) {
                transform: translateX(80px);
            }
        }
        color: $white;
        //background: transparent url("/assets/backgrounds/intro.png") 0% 0% no-repeat padding-box;
        //background-size: cover;
        h3 {
            @include media-breakpoint-up(md) {
                padding-left: 1rem;
            }
            z-index: 1;
        }
        .row {
            &.py {
                padding-top: 8em !important;
                padding-bottom: 4em !important;
            }
        }
        .pb-sm-4 {
            @include media-breakpoint-down(md) {
                padding-bottom: 4em !important;
            }
        }

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            .phrase {
                padding-top: 10px;
                margin-bottom: 0;
                color: $dark-blue;
            }
            img {
                max-width: 2.5rem !important;
                transition: 0.3s;
                &:hover {
                    transform: scale(1.3);
                }
            }
        }
        p {
            font-size: 0.85em;
            font-weight: 300;
        }
        .clouds {
            position: absolute;
            max-width: 110%;
            left: -5%;
            top: -50%;
        }
    }

    .setup {
        @include media-breakpoint-up(md) {
            margin-top: 20rem;
        }
        @include media-breakpoint-down(md) {
            &:not(.card-wrapper) {
                color: #FFFFFF !important;
                .text-purple {
                    color: unset !important;
                }
            }
            i {
                color: #FFFFFF !important;
            }
            .card-wrapper {
                color: $black !important;
            }
        }
        .mb-3rem {
            margin-bottom: 3rem;
        }
        color: $black;
        //background: transparent url("/assets/backgrounds/setup.png") 0% 0% no-repeat padding-box;
        //background-size: cover;
        .card-wrapper {
            border-radius: 25px;
            box-shadow: 0 0 10px #00000033;
            @include media-breakpoint-down(md) {
                max-width: 80%;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            .phrase {
                padding-left: 5px;
                padding-bottom: 0;
                margin-bottom: 0;
            }
            img {
                max-width: 80px !important;
            }
        }
        p {
            font-size: 0.85em;
            font-weight: 300;
            &.number {
                top: -10px;
                left: calc(var(--bs-gutter-x) * 0.5 - 10px);
                width: 20px;
                position: absolute;
                background: $purple;
                text-align: center;
                color: $white;
            }
        }
    }

    .container {
        max-width: 1320px;
    }

    #app {
        .bg-light-grey {
            background-color: #e9ecef;
        }
        @include media-breakpoint-up(xl) {
            min-height: 100vh;
        }
        @include media-breakpoint-down(md) {
            h2 {
                color: #FFFFFF !important;
                .text-purple {
                    color: unset !important;
                }
            }
        }
        #customerData {
            .card {
                border-radius: 20px;
            }
        }
        #nav-tabContent {
            border-top-left-radius: 0px !important;
            border-radius: 20px;
            .tab-pane {
                border-top-left-radius: 0px !important;
                border-radius: 20px;
            }
        }
        .button-wrapper {
            display: flex;
            flex-direction: row;
           @include media-breakpoint-down(xl) {
               flex-direction: column;
               button {
                   &:first-child {
                       margin-bottom: 1em;
                   }
               }
           }
        }
        .badge-secondary {
            background-color: $secondary !important;
            &.badge-pill {
                padding-right: 0.6em;
                padding-left: 0.6em;
                border-radius: 10rem;
            }
        }
        .prices {
            .col {
                .card {
                    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.2));
                    -webkit-filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.2));
                    border-top-right-radius: 65px;
                    border-bottom-left-radius: 100px;
                    transition: 0.3s;
                    &:hover {
                        transform: scale(1.03);
                    }

                    .card-header {
                        color: $white;
                        border-top-right-radius: 100px;
                    }
                }

                &:nth-child(1) {
                    .card-header {
                        background-color: #000000;
                    }

                    .card-body {
                        color: #000000;
                    }
                }

                &:nth-child(2) {
                    .card-header {
                        background-color: $purple;
                    }

                    .card-body {
                        color: $purple;
                    }
                }
            }
        }
        .nav-link {
            color: $dark-brown;
            &:hover {
                color: $dark-brown;
            }
        }

        .promo {
            .btn-danger {
                right: -50px;
                top: 0;
            }
        }

        .agb {
            max-height: 350px;
            overflow: scroll;
        }

        .osm {
            min-height: 200px;
            @include media-breakpoint-up(md) {
                min-height: 250px;
            }
            @include media-breakpoint-up(lg) {
                min-height: 300px;
            }
            .ol-viewport {
                border-radius: 0.25rem;
                min-height: 200px;
                @include media-breakpoint-up(md) {
                    min-height: 250px;
                }
                @include media-breakpoint-up(lg) {
                    min-height: 300px;
                }
            }
            .gcd-txt-container {
                @include media-breakpoint-down(md) {
                    width: 80% !important;
                    left: 10% !important;
                }
            }
            .gcd-txt-result {
                @include media-breakpoint-down(md) {
                    width: 100% !important;
                }
            }
        }
    }

    .simulation {
        position: relative;
        .btn-danger {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
    footer {
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
        color: #FFFFFF;
        font-weight: lighter;
        background-color: $light-black;
        @include media-breakpoint-down(sm) {
            position: relative;
        }
        .costum-class {
            font-size: 0.75em;
        }
        h3 {
            position: relative;
            &:after {
                content: '';
                width: 100%;
                position: absolute;
                height: 1px;
                bottom: 0;
                left: 0;
                background: $white;
            }
        }
        .border-after {
            @include media-breakpoint-up(md) {
                position: relative;
                &:after {
                    content: '';
                    width: 20px;
                    position: absolute;
                    height: 1px;
                    bottom: 50%;
                    right: -8px;
                    transform: rotate(90deg);
                    background: $white;
                }
            }
        }
        .payment {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                max-width: 100%;
                @include media-breakpoint-down(md) {
                    max-width: 100px;
                }
            }
        }
        .contact {
            @include media-breakpoint-down(md) {
                svg {
                    height: 50px;
                    width: 50px;
                }
            }
        }
        .links {
            display: flex;
            flex-direction: column;
            a {
                color: $white;
                text-decoration: none;
            }
        }
        .mw-60 {
            max-width: 60% !important;
        }
    }

    .document {
        @include media-breakpoint-down(md) {
            padding-top: 3rem !important;
        }
        .arguments {
            @include media-breakpoint-down(md) {
                .col {
                    padding-top: 2rem;
                }
            }
            .image-wrapper {
                border-radius: 1em;
                border: 1px solid $purple;
                background: $white;

                img {
                    max-width: 100%;
                }
            }

            p {
                border-radius: 0.5em;
                background: $purple;
                color: $white;
                box-shadow: inset 0 0.5rem 1rem rgb(0 0 0 / 15%);
            }
        }
        .download {
            padding-bottom: 5rem;
            img {
                max-width: 80%;
            }

        }
    }

    //global content styles
    .amortisation, .problems, .data, .profi, .letsgo {
        padding-top: 4rem;
        padding-bottom: 5rem;
        .container {
            .col-12 {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
    .amortisation, .problems, .data, .profi, .letsgo, .form {
        @include media-breakpoint-down(md) {
            color: #FFFFFF !important;
            .text-purple {
                color: unset !important;
            }
            .btn-primary {
                border-color: $white;

                &:hover {
                    background-color: $white;
                    color: $purple;
                }
            }
        }
    }

    .form {
        padding-top: 2rem;
        padding-bottom: 4rem;
    }

    //swal styles
    .swal-modal {
        border: 1px solid $purple;
    }
    .swal-button {
        &:focus {
            box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgb(111 66 193 / 29%);
        }
    }
    .swal-button--confirm {
        background-color: $purple !important;
        &:hover {
            background-color: $dark-purple !important;
        }
    }
}

// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Kigelia LGC', sans-serif;
$font-family-ubuntu: 'Ubuntu', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;


$black: #000000;
$light-black: #161616;
$white: #FFFFFF;
$purple: #C016F2;
$dark-purple: #9a17b9;
$dark-blue: #003290;
$light-blue: #2F75F7;
$dark-brown: #161616;
$dark-red: #820000;

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

@import '~ol-geocoder/dist/ol-geocoder.min.css';

// Variables
@import 'variables';

@import "landingpage";

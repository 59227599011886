$scale: 45px;
.polygon-light {
    position: absolute;
    background-color: transparent;
    width: 165px;
    height: 165px;
    transition: 0.3s;
    img {
        filter: drop-shadow( -5px -5px 10px rgba(0, 0, 0, .3));
        -webkit-filter: drop-shadow( -5px -5px 10px rgba(0, 0, 0, .3));
    }
    &:hover {
        transform: scale(1.05);
    }
    &.medium {
        width: 130px;
        height: 130px;
    }
    &.small {
        width: 80px;
        height: 80px;
    }
    &.lighter {
        opacity: 0.7;
    }
    &-1 {
        top: 144px;
        left: 45px;
    }
    &-2 {
        top: -198px;
        left: 224px;
    }
    &-3 {
        top: -16px;
        left: 400px;
    }
    &-4 {
        top: -134px;
        left: 380px;
    }
    //medium ones
    &-5 {
        top: 144px;
        left: 444px;
    }
    &-6 {
        top: 272px;
        left: 482px;
    }
    &-7 {
        top: 258px;
        left: -46px;
    }
    &-8 {
        top: 312px;
        left: 94px;
    }
    &-9 {
        top: 56px;
        left: 568px;
    }
    &-10 {
        top: -134px;
        left: -6px;
    }
    &-11 {
        top: -214px;
        left: -186px;
    }
    //small ones
    &-12 {
        top: -236px;
        left: -262px;
    }
    &-13 {
        top: 144px;
        left: -38px;
    }
    &-14 {
        top: 292px;
        left: -206px;
    }
    &-15 {
        top: -82px;
        left: 520px;
    }
    &-16 {
        top: -228px;
        left: 482px;
    }
}
@include media-breakpoint-up(xl) {
    .polygon-light {
        position: absolute;
        background-color: transparent;
        width: calc(165px + $scale);
        height: calc(165px + $scale);
        transition: 0.3s;
        &:hover {
            transform: scale(1.05);
        }
        &.medium {
            width: calc(130px + $scale);
            height: calc(130px + $scale);
        }
        &.small {
            width: calc(80px + $scale);
            height: calc(80px + $scale);
        }
        &.lighter {
            opacity: 0.7;
        }
        &-1 {
            top: 184px;
            left: 52px;
        }
        &-2 {
            top: -248px;
            left: 288px;
        }
        &-3 {
            top: -10px;
            left: 506px;
        }
        &-4 {
            top: -172px;
            left: 480px;
        }
        //medium ones
        &-5 {
            top: 190px;
            left: 562px;
        }
        &-6 {
            top: 358px;
            left: 610px;
        }
        &-7 {
            top: 336px;
            left: -84px;
        }
        &-8 {
            top: 392px;
            left: 106px;
        }
        &-9 {
            top: 70px;
            left: 738px;
        }
        &-10 {
            top: -188px;
            left: -26px;
        }
        &-11 {
            top: -248px;
            left: -246px;
        }
        //small ones
        &-12 {
            top: 172px;
            left: -78px;
        }

        &-13 {
            top: 338px;
            left: -292px;
        }

        &-14 {
            top: -112px;
            left: 682px;
        }

        &-15 {
            top: -360px;
            left: 622px;
        }

        &-16 {
            top: -272px;
            left: -468px;
        }
    }
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
    background-color: transparent;
    width: 165px;
    height: 165px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
    -moz-perspective: 1000px;
    @include media-breakpoint-up(xl) {
        width: calc(165px + $scale);
        height: calc(165px + $scale);
    }
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    -moz-transition: transform 0.8s;
    -moz-transform-style: preserve-3d;
    -moz-backface-visibility: hidden;
    img {
        max-width: 100%;
        height: auto;
        filter: drop-shadow( -5px -5px 10px rgba(0, 0, 0, .3));
        -webkit-filter: drop-shadow( -5px -5px 10px rgba(0, 0, 0, .3));
    }
    p {
        @include media-breakpoint-up(xl) {
            font-size: 0.95rem !important;
            font-weight: 400 !important;
        }
        &.front {
            font-family: 'Roboto';
            @include media-breakpoint-up(xl) {
                font-size: 1.5em !important;
            }
            position: absolute;
            top: 42%;
            left: 32%;
            @include media-breakpoint-up(xl) {
                top: 32%;
                left: 22%;
            }
        }
        &.back {
            position: absolute;
            top: 35%;
            left: 20%;
            max-width: 75%;
        }
    }
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
    transform: rotateX(0deg) rotateY(180deg);
    -webkit-transform: rotateX(0deg) rotateY(180deg);
    -moz-transform: rotateX(0deg) rotateY(-179deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    -moz-backface-visibility: hidden; /* Mozilla */
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
    background-color: transparent;
    color: $white;
}

/* Style the back side */
.flip-card-back {
    background-color: transparent;
    color: $white;
    transform: rotateX(0deg) rotateY(180deg);
    -webkit-transform: rotateX(0deg) rotateY(180deg);
    -moz-transform: rotateX(0deg) rotateY(-179deg);
}


.flip-card:nth-child(1) {
    p {
        &.front {
            top: 42%;
            left: 32%;
            @include media-breakpoint-up(xl) {
                top: 34%;
                left: 16%;
            }
        }
        &.back {
            top: 22%;
            left: 14%;
            @include media-breakpoint-up(xl) {
                top: 24%;
                left: 12%;
            }
        }
    }
}
.flip-card:nth-child(2) {
    position: absolute;
    top: -100px;
    left: 118px;
     @include media-breakpoint-up(xl) {
         top: -124px;
         left: 150px;
     }
    .flip-card-inner {
        p {
            &.front {
                top: 40%;
                left: 32%;
                @include media-breakpoint-up(xl) {
                    top: 32%;
                    left: 22%;
                }
            }
            &.back {
                top: 24%;
                left: 14%;
                @include media-breakpoint-up(xl) {
                    top: 26%;
                    left: 12%;
                }
            }
        }
    }
}

.flip-card:nth-child(3) {
    position: absolute;
    left: 152px;
    top: 42px;
     @include media-breakpoint-up(xl) {
         left: 190px;
         top: 58px;
     }
    p {
        &.front {
            @include media-breakpoint-up(xl) {
                top: 34%;
                left: 24%;
            }
        }
        &.back {
            top: 22%;
            left: 12%;
            @include media-breakpoint-up(xl) {
                top: 28%;
                left: 13%;
            }
        }
    }
}

.flip-card:nth-child(4) {
    left: 258px;
    position: absolute;
    top: -58px;
     @include media-breakpoint-up(xl) {
         left: 328px;
         top: -68px;
     }
    p {
        &.front {
            top: 40%;
            left: 32%;
            @include media-breakpoint-up(xl) {
                top: 32%;
                left: 22%;
            }
        }
        &.back {
            top: 32%;
            left: 12%;
            @include media-breakpoint-up(xl) {
                top: 34%;
                left: 12%;
            }
        }
    }
}

.flip-card:nth-child(5) {
    position: absolute;
    top: 84px;
    left: 292px;
     @include media-breakpoint-up(xl) {
         top: 114px;
         left: 368px
     }
    p {
        &.front {
            top: 40%;
            left: 34%;
            @include media-breakpoint-up(xl) {
                top: 32%;
                left: 26%;
            }
        }
        &.back {
            top: 26%;
            left: 12%;
            @include media-breakpoint-up(xl) {
                top: 30%;
                left: 12%;
            }
        }
    }
}

.flip-card:nth-child(6) {
    position: absolute;
    top: 184px;
    left: 186px;
     @include media-breakpoint-up(xl) {
         top: 240px;
         left: 230px;
     }
    p {
        &.front {
            top: 40%;
            left: 28%;
            @include media-breakpoint-up(xl) {
                top: 32%;
                left: 18%;
            }
        }
        &.back {
            top: 20%;
            left: 12%;
            @include media-breakpoint-up(xl) {
                top: 26%;
                left: 12%;
            }
        }
    }
}

.flip-card:nth-child(7) {
    position: absolute;
    left: 326px;
    top: 226px;
     @include media-breakpoint-up(xl) {
         left: 408px;
         top: 296px;
     }
    p {
        &.back {
            top: 20%;
            left: 12%;
            @include media-breakpoint-up(xl) {
                top: 32%;
                left: 12%;
            }
        }
    }
}
